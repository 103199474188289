import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 28.07 28.7 L 28.07 0 L 22.54 0 L 22.54 28.7 A 16.377 16.377 0 0 1 22.188 32.248 C 21.209 36.648 18.245 38.57 14 38.57 A 9.257 9.257 0 0 1 10.384 37.896 C 8.312 37.023 6.866 35.332 6.131 33.005 A 11.851 11.851 0 0 1 5.67 30.8 L 0 30.8 A 14.697 14.697 0 0 0 2.421 38.112 C 4.773 41.534 8.743 43.61 14.07 43.61 C 22.26 43.61 28.07 38.71 28.07 28.7 Z"
          fill="currentColor"
          transform="translate(21,22)"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
