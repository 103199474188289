import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(36, 33)" fill="#64FFDA" fontFamily="Calibre-Medium, Calibre" fontSize="50" fontWeight="400" letterSpacing="4.16666603">
        <text>
          <tspan x="0.141666985" y="33">J</tspan>
        </text>
      </g>
    <path 
      stroke="#64FFDA" 
      strokeWidth="5" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
          />
      </g>
  </svg>
);

export default IconLoader;
